import { observable, action, makeAutoObservable } from 'mobx';
import CompanyInforStore, {
  CompanyInforStoreProps,
} from './CorrelationGraph/CompanyInforStore';
import EventStore, { EventStoreProps } from './CorrelationGraph/EventStore';
import NewsStore, { NewsStoreProps } from './CorrelationGraph/NewsStore';
import RRGStore, { RRGStoreProps } from './CorrelationGraph/RRGStore';
import CorrelationGraphChartStore, {
  CorrelationGraphChartProps,
} from './CorrelationGraph/CorrelationGraphChartStore';
import { Global2kData } from './globalStore';
import WSClient, { WSClientProps } from './wsClient';
import TCTTChartStore, { TCTTChartProps } from './TCTTChartStore';
import khoiNgoaiChartStore, {
  KhoiNgoaiChartProps,
} from './khoiNgoaiChartStore';
import CG1GeneralStore, {
  CG1GeneralStoreProps,
} from './CorrelationGraph/CG1GeneralStore';
import RRGWatchListStore, { RRGWatchListProps } from './RRGWatchListStore';
import topListStore, { TopListProps } from './topListStore';
import CG3TopRRGStore, {
  CG3TopRRGStoreProps,
} from './CorrelationGraph/CG3TopRRGStore';
import CGTopRatingStore, {
  CGTopRatingProps,
} from './CorrelationGraph/CGTopRatingStore';
import FinancialIndicatorsStore, {
  FinancialIndicatorsStoreProps,
} from './CorrelationGraph/DuLieuTaiChinh/FinancialIndicatorsStore';
import BaoCaoTaiChinhStore, {
  BaoCaoTaiChinhStoreProps,
} from './CorrelationGraph/DuLieuTaiChinh/BaoCaoTaiChinhStore';
import ThongKeChungStore, {
  ThongKeChungStoreProps,
} from './CorrelationGraph/DuLieuTaiChinh/ThongKeChungStore';
import TabFinanceStore, {
  TabFinanceStoreProps,
} from './CorrelationGraph/TabFinanceStore';

export interface CorrelationGraphProps {
  setTabValue: (tabValue: number) => void;
  tabValue: number;
  dulieTaiChinhMenuItem: number | null;
  isClapse: boolean;
  isClapseToplist: boolean;
  setDulieTaiChinhMenuItem: (dulieTaiChinhMenuItem: number) => void;
  startWebSocket: () => void;
  endWebSocket: () => void;
  setIsClapse: () => void;
  setIsClapseToplist: () => void;
  companyInforStore: CompanyInforStoreProps;
  eventStore: EventStoreProps;
  newsStore: NewsStoreProps;
  rrgStore: RRGStoreProps;
  correlationGraphChartStore: CorrelationGraphChartProps;
  setCodeFromRightPanel: (code: string, type?: number) => void;

  tCTTChartStore: TCTTChartProps;
  khoiNgoaiChartStore: KhoiNgoaiChartProps;
  cG1GeneralStore: CG1GeneralStoreProps;
  rRGWatchListStore: RRGWatchListProps;
  topListStore: TopListProps;
  cG3TopRRGStore: CG3TopRRGStoreProps;
  cG4TopRatingStore: CGTopRatingProps;
  cG5TopRatingStore: CGTopRatingProps;
  cG6TopRatingStore: CGTopRatingProps;
  cG7TopRatingStore: CGTopRatingProps;

  financialIndicatorsStore: FinancialIndicatorsStoreProps;
  financialComparesStore: FinancialIndicatorsStoreProps;
  baoCaoTaiChinhStore: BaoCaoTaiChinhStoreProps;
  thongKeChungStore: ThongKeChungStoreProps;
  tabFinanceStore: TabFinanceStoreProps;
}

class CorrelationGraphStore implements CorrelationGraphProps {
  private echoChannel: string = 'AllStockData';
  private wsClient: WSClientProps = WSClient;
  companyInforStore: CompanyInforStoreProps;
  eventStore: EventStoreProps;
  newsStore: NewsStoreProps;
  rrgStore: RRGStoreProps;
  correlationGraphChartStore: CorrelationGraphChartProps;
  tCTTChartStore: TCTTChartProps;
  khoiNgoaiChartStore: KhoiNgoaiChartProps;
  cG1GeneralStore: CG1GeneralStoreProps;
  rRGWatchListStore: RRGWatchListProps;
  topListStore: TopListProps;
  cG3TopRRGStore: CG3TopRRGStoreProps;
  cG4TopRatingStore: CGTopRatingProps;
  cG5TopRatingStore: CGTopRatingProps;
  cG6TopRatingStore: CGTopRatingProps;
  cG7TopRatingStore: CGTopRatingProps;
  financialIndicatorsStore: FinancialIndicatorsStoreProps;
  financialComparesStore: FinancialIndicatorsStoreProps;
  baoCaoTaiChinhStore: BaoCaoTaiChinhStoreProps;
  thongKeChungStore: ThongKeChungStoreProps;
  tabFinanceStore: TabFinanceStoreProps;

  private dataList: Array<Global2kData> = [];

  @observable
  openNewPageTaiChinh: boolean = false;

  @observable
  openEditNameTaiChinh: boolean = false;

  @observable
  isClapseToplist: boolean = false;

  @observable
  isClapse: boolean = false;

  @observable
  tabValue: number = 1;

  @observable
  dulieTaiChinhMenuItem: number | null = null;

  constructor() {
    this.companyInforStore = new CompanyInforStore();
    this.eventStore = new EventStore();
    this.newsStore = new NewsStore();
    // this.newsStore.loadData();
    this.rrgStore = new RRGStore();
    this.correlationGraphChartStore = new CorrelationGraphChartStore();
    this.tCTTChartStore = new TCTTChartStore();
    this.khoiNgoaiChartStore = new khoiNgoaiChartStore();
    // this.tCTTChartStore.setDataTypeCode('VND', 'KLGD');
    // this.khoiNgoaiChartStore.setDataCode('VND');
    this.cG1GeneralStore = CG1GeneralStore;
    this.rRGWatchListStore = RRGWatchListStore;
    this.topListStore = topListStore;
    this.cG3TopRRGStore = CG3TopRRGStore;
    this.cG4TopRatingStore = new CGTopRatingStore();
    this.cG5TopRatingStore = new CGTopRatingStore();
    this.cG6TopRatingStore = new CGTopRatingStore();
    this.cG7TopRatingStore = new CGTopRatingStore();
    this.financialIndicatorsStore = new FinancialIndicatorsStore();
    this.financialComparesStore = new FinancialIndicatorsStore();
    this.financialComparesStore.setType(2);
    this.financialComparesStore.setFIFilter('timePeroidTypes', 1);
    this.financialComparesStore.loadCompareCodeData();
    this.baoCaoTaiChinhStore = new BaoCaoTaiChinhStore();
    this.thongKeChungStore = new ThongKeChungStore();
    this.tabFinanceStore = new TabFinanceStore();
    makeAutoObservable(this);
  }

  @action
  public setIsClapseToplist = () => {
    this.isClapseToplist = !this.isClapseToplist;
  };

  @action
  public setIsClapse = () => {
    this.isClapse = !this.isClapse;
  };

  @action
  public setCodeFromRightPanel = (code: string, type?: number) => {
    if (type !== undefined) {
      this.companyInforStore.setType(type);
    }

    this.companyInforStore.setCode(code);
    this.tCTTChartStore.setDataTypeCode(code, 'KLGD');
    this.khoiNgoaiChartStore.setDataCode(code);

    if (!(type !== undefined && type === 2)) {
      this.correlationGraphChartStore.setCode(code, this.tabValue === 1);
      this.rrgStore.setCode(code, this.tabValue === 2);
      this.tabFinanceStore.setCode(code, this.tabValue === 3);
      this.thongKeChungStore.setCode(
        code,
        this.tabValue === 4 && this.dulieTaiChinhMenuItem === 0,
      );
      this.financialIndicatorsStore.setCode(
        code,
        this.tabValue === 4 && this.dulieTaiChinhMenuItem === 1,
      );
      this.baoCaoTaiChinhStore.setCode(
        code,
        this.tabValue === 4 && this.dulieTaiChinhMenuItem === 2,
      );
      this.financialComparesStore.setCompareCode(
        code,
        this.tabValue === 4 && this.dulieTaiChinhMenuItem === 3,
      );
      this.eventStore.setCode(code, this.tabValue === 6);
    }
    this.newsStore.setCode(code);
  };

  @action
  public setTabValue = (tabValue: number) => {
    if (tabValue === 1) {
      this.correlationGraphChartStore.loadData();
    }
    if (tabValue === 2) {
      this.rrgStore.loadDataVNIndex();
      this.rrgStore.loadDataCodes();
    }
    if (tabValue === 3) {
      this.isClapse = true;
      this.tabFinanceStore.loadDataCustomize();
      // this.tabFinanceStore.loadData();
    }
    if (tabValue === 6) {
      this.eventStore.loadDataTypes();
      this.eventStore.loadData();
    }
    if (tabValue === 7) {
      this.companyInforStore.loadData();
    }
    this.tabValue = tabValue;
  };

  @action
  public setDulieTaiChinhMenuItem = (dulieTaiChinhMenuItem: number) => {
    this.dulieTaiChinhMenuItem = dulieTaiChinhMenuItem;
    // if (this.dulieTaiChinhMenuItem === 1) {
    //   this.chiSoTaiChinhStore.loadData();
    // }
  };

  @action
  public startWebSocket = () => {
    this.wsClient.listen(
      this.fetchDataSocketSuccess,
      this.echoChannel,
      this.echoChannel,
    );
  };

  @action
  public endWebSocket = () => {
    this.dataList = [];
    this.wsClient.leaveChannel(this.echoChannel);
  };

  @action
  private fetchDataSocketSuccess = (data: {
    end: 0 | 1;
    data: Array<Global2kData>;
  }) => {
    if (!data.end) {
      this.dataList = [...this.dataList, ...data.data];
    }
    if (data.end) {
      // const dataTmp: Array<Global2kData> = [...this.dataList, ...data.data];
      this.dataList = [];
    }
    this.updateCorrelationGraphChartData(data.data);
    this.updateCG1GeneralData(data.data);
    this.updateCG3TopRRGData(data.data);
    this.updateCGTopRSData(data.data, this.cG4TopRatingStore);
    this.updateCGTopRSData(data.data, this.cG5TopRatingStore);
    this.updateCGTopRSData(data.data, this.cG6TopRatingStore);
    this.updateCGTopRSData(data.data, this.cG7TopRatingStore);
    this.updateThongKeChungData(data.data);
    this.updateCompanyInforData(data.data);
  };

  @action
  private updateThongKeChungData = (dataSocket: Array<Global2kData>) => {
    const { data, code, updateData } = this.thongKeChungStore;
    if (dataSocket.length > 0 && data.data.length > 0) {
      const dataSt = dataSocket.find(
        (t) => t.code.toString().toLowerCase() === code.toLowerCase(),
      );
      if (dataSt) {
        updateData({
          ...data,
          floorPrice: parseFloat(dataSt.floorPrice ?? '0'),
          ceilingPrice: parseFloat(dataSt.ceilingPrice ?? '0'),
          adChange: parseFloat(dataSt.adChange),
          adClose: parseFloat(dataSt.adClose),
          pctChange: parseFloat(dataSt.pctChange),
        });
      }
    }
  };

  @action
  private updateCorrelationGraphChartData = (
    dataSocket: Array<Global2kData>,
  ) => {
    const {
      dataGraph: { data },
      code,
      updateDataSocket,
    } = this.correlationGraphChartStore;
    if (dataSocket.length > 0 && data.length > 0) {
      const dataSt = dataSocket.find(
        (t) => t.code.toString().toLowerCase() === code.toLowerCase(),
      );
      // let dataUpdate = data.slice(0, data.length - 1);
      if (dataSt && data[data.length - 1]) {
        let datChange = data[data.length - 1];
        datChange = {
          ...datChange,
          close: parseFloat(dataSt.adClose),
          open: parseFloat(dataSt.adOpen),
          high: parseFloat(dataSt.adHigh),
          low: parseFloat(dataSt.adLow),
          pctChange: parseFloat(dataSt.pctChange),
          volume: parseFloat(dataSt.nmVolume),
          rsRating: parseFloat(dataSt.rsRating),
        };
        // dataUpdate = [...dataUpdate, datChange];
        updateDataSocket(datChange);
      }
    }
  };

  @action
  private updateCompanyInforData = (data2K: Array<Global2kData>) => {
    const { data, code, updateData } = this.companyInforStore;
    if (data2K.length > 0 && data) {
      const dataSt = data2K.find(
        (t) => t.code.toString().toLowerCase() === code.toLowerCase(),
      );
      if (dataSt) {
        updateData({
          ...data,
          floorPrice: parseFloat(dataSt.floorPrice ?? '0'),
          ceilingPrice: parseFloat(dataSt.ceilingPrice ?? '0'),
          adChange: parseFloat(dataSt.adChange),
          adClose: parseFloat(dataSt.adClose),
          pctChange: parseFloat(dataSt.pctChange),
          nmVolume: parseFloat(dataSt.nmVolume),
        });
      }
    }
  };

  @action
  private updateCG1GeneralData = (data2K: Array<Global2kData>) => {
    const { selectedMarket, updateData } = this.cG1GeneralStore;
    if (selectedMarket.data.length > 0 && data2K.length > 0) {
      let dataTMP = selectedMarket.data.slice();
      let dataUpdate = dataTMP.map((t: any, _i: number) => {
        const itemChange = data2K.find((s) => s.code === t.code);
        if (itemChange) {
          return {
            ...t,
            adClose: itemChange.adClose,
            adChange: itemChange.adChange,
            pctChange: itemChange.pctChange,
            nmVolume: itemChange.nmVolume,
            nmValue: itemChange.nmValue,
            statusAdClose:
              parseFloat(itemChange.adClose) > parseFloat(t.adClose.toString())
                ? 1
                : parseFloat(itemChange.adClose) <
                  parseFloat(t.adClose.toString())
                ? 2
                : 0,
            statusPctChange:
              parseFloat(itemChange.pctChange) >
              parseFloat(t.pctChange.toString())
                ? 1
                : parseFloat(itemChange.pctChange) <
                  parseFloat(t.pctChange.toString())
                ? 2
                : 0,
            statusNmVolume:
              parseFloat(itemChange.nmVolume) >
              parseFloat(t.nmVolume.toString())
                ? 1
                : parseFloat(itemChange.nmVolume) <
                  parseFloat(t.nmVolume.toString())
                ? 2
                : 0,
          };
        } else return t;
      });
      updateData(dataUpdate);
    }
  };

  @action
  private updateCG3TopRRGData = (data2K: Array<Global2kData>) => {
    const { data, updateData } = this.cG3TopRRGStore;
    if (data.length > 0 && data2K.length > 0) {
      let dataTMP = data.slice();
      let dataUpdate = dataTMP.map((t: any, _i: number) => {
        const itemChange = data2K.find((s) => s.code === t.code);
        if (itemChange) {
          return {
            ...t,
            adClose: itemChange.adClose,
            adChange: itemChange.adChange,
            pctChange: itemChange.pctChange,
            nmVolume: itemChange.nmVolume,
            nmValue: itemChange.nmValue,
            statusAdClose:
              parseFloat(itemChange.adClose) > parseFloat(t.adClose.toString())
                ? 1
                : parseFloat(itemChange.adClose) <
                  parseFloat(t.adClose.toString())
                ? 2
                : 0,
            statusPctChange:
              parseFloat(itemChange.pctChange) >
              parseFloat(t.pctChange.toString())
                ? 1
                : parseFloat(itemChange.pctChange) <
                  parseFloat(t.pctChange.toString())
                ? 2
                : 0,
            statusNmVolume:
              parseFloat(itemChange.nmVolume) >
              parseFloat(t.nmVolume.toString())
                ? 1
                : parseFloat(itemChange.nmVolume) <
                  parseFloat(t.nmVolume.toString())
                ? 2
                : 0,
          };
        } else return t;
      });
      updateData(dataUpdate);
    }
  };

  @action
  private updateCGTopRSData = (
    data2K: Array<Global2kData>,
    cGTOPStore: CGTopRatingProps,
  ) => {
    const { data, updateData } = cGTOPStore;
    if (data.length > 0 && data2K.length > 0) {
      let dataTMP = data.slice();
      let dataUpdate = dataTMP.map((t: any, _i: number) => {
        const itemChange = data2K.find((s) => s.code === t.code);
        if (itemChange) {
          return {
            ...t,
            adClose: itemChange.adClose,
            adChange: itemChange.adChange,
            pctChange: itemChange.pctChange,
            nmVolume: itemChange.nmVolume,
            nmValue: itemChange.nmValue,
            statusAdClose:
              parseFloat(itemChange.adClose) > parseFloat(t.adClose.toString())
                ? 1
                : parseFloat(itemChange.adClose) <
                  parseFloat(t.adClose.toString())
                ? 2
                : 0,
            statusPctChange:
              parseFloat(itemChange.pctChange) >
              parseFloat(t.pctChange.toString())
                ? 1
                : parseFloat(itemChange.pctChange) <
                  parseFloat(t.pctChange.toString())
                ? 2
                : 0,
            statusNmVolume:
              parseFloat(itemChange.nmVolume) >
              parseFloat(t.nmVolume.toString())
                ? 1
                : parseFloat(itemChange.nmVolume) <
                  parseFloat(t.nmVolume.toString())
                ? 2
                : 0,
          };
        } else return t;
      });
      updateData(dataUpdate);
    }
  };
}

export default new CorrelationGraphStore();
