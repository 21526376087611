import React from 'react';
import { styled, Theme, CSSObject } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import List from '@mui/material/List';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import { useParams } from 'react-router-dom';
import { Button } from '@mui/material';
import { AppMenuItem } from '../shared';
import { AppGuideUrl, drawerWidth } from '../../const/config';
import AppLogo from '../shared/AppLogo';
import AppHomeIcon from '../shared/icons/AppHomeIcon';
import AppRRGIcon from '../shared/icons/AppRRGIcon';
import AppTradingSignalIcon from '../shared/icons/AppTradingSignalIcon';
import AppRecommendationListIcon from '../shared/icons/AppRecommendationListIcon';
import AppStock360Icon from '../shared/icons/AppStock360Icon';
import AppIndustryVolatilityIcon from '../shared/icons/AppIndustryVolatilityIcon';
import AppTopStockIcon from '../shared/icons/AppTopStockIcon';
import AppChartPyramidIcon from '../shared/icons/AppChartPyramidIcon';
import AppChartTreeIcon from '../shared/icons/AppChartTreeIcon';
import AppNotificationIcon from '../shared/icons/AppNotificationIcon';
import AppSettingTopIcon from '../shared/icons/AppSettingTopIcon';
import AppGuideIcon from '../shared/icons/AppGuideIcon';
import { useStores } from '../../store/useStores';
import { AppUserInfoButton } from '../shared/AppUserInfoButton';
import AppIndustryDevelopIcon from '../shared/icons/AppIndustryDevelopIcon';
import AppUpgradeIcon from '../shared/icons/AppUpgradeIcon';
import StockSelection from '../shared/StockSelection';
import { useForm } from 'react-hook-form';
import { observer } from 'mobx-react';
import { useNavigate, createSearchParams } from 'react-router-dom';
import zalo from '../shared/icons/zalo.png';

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

interface AppLayoutProps {
  children?: React.ReactNode;
}

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: '50px',
  [theme.breakpoints.up('sm')]: {
    width: `50px`,
  },
});

const MainWrapper = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  background: theme.palette.background.default,
  // flexGrow: 1,
  // height: '100%',
  overflow: 'hidden',
  width: '100%',
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  position: 'inherit',
  // width: `calc(100% - 50px)`,
  height: '53px',
  minHeight: '53px',
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: '50px',
    width: `calc(100% - 50px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));

const AppLayout: React.FC<AppLayoutProps> = observer(({ children }) => {
  const { authStore, listStockStore } = useStores();
  const { isAuthenticated, userData } = authStore;
  React.useEffect(() => {
    listStockStore.loadData();
  }, [listStockStore]);
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const params = useParams();
  const toggleDrawer = () => {
    setOpen(!open);
  };
  const { control, reset } = useForm<any>();
  return (
    <Box sx={{ display: 'flex', height: '100%' }}>
      <Drawer
        className="home-icon"
        variant="permanent"
        open={open}
        onClose={toggleDrawer}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '53px',
          }}
        >
          <AppLogo />
        </Box>
        <List sx={{ width: open ? 'auto' : '50px' }}>
          <AppMenuItem
            active={!params.id}
            path=""
            icon={AppHomeIcon}
            title="Trang tổng quan"
            open={open}
          />
          <AppMenuItem
            active={params.id === 'trading-signal'}
            path="trading-signal"
            icon={AppTradingSignalIcon}
            title="Tín hiệu giao dịch"
            open={open}
            badge={'Beta'}
          />
          <AppMenuItem
            active={params.id === 'idea-trading'}
            path="idea-trading"
            icon={AppRecommendationListIcon}
            title="Ý tưởng đầu tư"
            open={open}
            badge={'VIP'}
          />
          <AppMenuItem
            active={params.id === 'relative-rotation-graphs'}
            path="relative-rotation-graphs"
            icon={AppRRGIcon}
            title="Hệ thống RRG"
            open={open}
          />
          <AppMenuItem
            active={params.id === 'stock360'}
            path="stock360"
            icon={AppStock360Icon}
            title="Cổ phiếu 360"
            open={open}
            badge={'Pro'}
          />
          <AppMenuItem
            active={
              params.id === 'industry-developments' ||
              params.id === 'industry-developments-stock'
            }
            path="industry-developments"
            icon={AppIndustryDevelopIcon}
            title="Diễn biến ngành"
            open={open}
          />
          <AppMenuItem
            active={
              params.id === 'industry-volatility' ||
              params.id === 'industry-volatility-stock'
            }
            path="industry-volatility"
            icon={AppIndustryVolatilityIcon}
            title="Biến động ngành"
            open={open}
          />
          <AppMenuItem
            path="reporting-analysis"
            active={params.id === 'reporting-analysis'}
            icon={AppChartPyramidIcon}
            title="Báo cáo phân tích"
            open={open}
          />
          <AppMenuItem
            path="investment-strategy"
            active={params.id === 'investment-strategy'}
            icon={AppChartTreeIcon}
            title="Chiến lược giao dịch"
            open={open}
          />
          <AppMenuItem
            active={params.id === 'top-stock'}
            path="top-stock"
            icon={AppTopStockIcon}
            title="TOP Cổ phiếu"
            open={open}
          />
        </List>
      </Drawer>
      <MainWrapper open={open}>
        {!(
          params.id === 'login' ||
          params.id === 'forgot' ||
          params.id === 'reset'
        ) && (
          <AppBar position="absolute" open={open}>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                height: '100%',
                padding: '20px 10px 15px 9px',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  width: '100%',
                }}
              >
                <StockSelection
                  isDisabled={listStockStore.loading}
                  name="stockID"
                  control={control}
                  labelKey="name"
                  valueKey="id"
                  placeholder="Xem chi tiết mã Cổ phiếu"
                  width={220}
                  options={listStockStore.listStock.slice()}
                  onChangeComplete={(code: any) => {
                    const stockCode = code.split(';');
                    navigate({
                      pathname: '/stock360',
                      search: `?${createSearchParams({
                        cp: stockCode[0],
                      })}`,
                    });
                  }}
                />

                <Box>
                  <Button
                    href="/upgrade"
                    sx={{
                      visibility: 'hidden',
                      textTransform: 'none',
                      marginLeft: '10px',
                      marginRight: '10px',
                    }}
                    variant="outlined"
                    size="small"
                    startIcon={<AppUpgradeIcon />}
                  >
                    Nâng cấp
                  </Button>
                  <IconButton
                    color="inherit"
                    href="https://zalo.me/174277045100757574"
                    target="_blank"
                  >
                    <img src={zalo} alt="Zalo" style={{ width: 28, height: 28, background: 'white', borderRadius: 5 }}/>
                  </IconButton>
                  <IconButton
                    color="secondary"
                    href={AppGuideUrl || ''}
                    target="_blank"
                  >
                    <AppGuideIcon />
                  </IconButton>
                  {/* <IconButton sx={{ ml: 1 }} onClick={colorMode.toggleColorMode} color="inherit">
                                    {theme.palette.mode === 'dark' ? <AppColorModeIcon /> : <Brightness4Icon />}
                                </IconButton> */}
                  <IconButton color="inherit">
                    <AppSettingTopIcon />
                  </IconButton>
                  <IconButton color="inherit">
                    <Badge badgeContent={0} color="error">
                      <AppNotificationIcon />
                    </Badge>
                  </IconButton>
                  {isAuthenticated ? (
                    <AppUserInfoButton userData={userData} />
                  ) : (
                    <>
                      <Button
                        href="/login"
                        sx={{
                          textTransform: 'none',
                          marginLeft: '10px',
                          marginRight: '10px',
                        }}
                        variant="outlined"
                        size="small"
                      >
                        Đăng nhập
                      </Button>
                      <Button
                        href="/signup"
                        sx={{ textTransform: 'none', marginRight: '10px' }}
                        variant="outlined"
                        size="small"
                      >
                        Đăng ký
                      </Button>
                    </>
                  )}
                </Box>
              </Box>
            </Box>
          </AppBar>
        )}
        <Box sx={{ overflow: 'hidden' }}>{children}</Box>
      </MainWrapper>
    </Box>
  );
});

export default AppLayout;