import React from 'react';
import authStore from './authStore';
import NewActionStore from './newActionStore';
import TrendingStore from './trendingStore';
import PTTBChartStore from './PTTBChartStore';
import TCTTChartStore from './TCTTChartStore';
import KhoiNgoaiChartStore from './khoiNgoaiChartStore';
import KhoiNgoaiTPChartStore from './khoiNgoaiTPChartStore';
import CanhBaoStore from './canhBaoStore';
import TopCoPhieuStore from './topcophieuStore';
import TopListStore from './topListStore';
import MarketChartStore from './marketChartStore';
import MarketIndexStore from './marketIndexStore';
import RGGChartStore from './RRGChartStore';
import RRGWatchListStore from './RRGWatchListStore';
import RGGHomeChartStore from './RRGHomeChartStore';
import InvestmentStrategyStore from './InvestmentStrategyStore';
import ReportingAnalysisStore from './ReportingAnalysisStore';
import IndustryVolatilityStore from './industryVolatilityStore';
import IndustryDevelopStore from './industryDevelopStore';
import TopStockStore from './TopStockStore';
import RecommendedStore from './RecommendedStore';
import CorrelationGraphStore from './CorrelationGraphStore';
import CG1GeneralStore from './CorrelationGraph/CG1GeneralStore';
import CG2TransInfoStore from './CorrelationGraph/CG2TransInfoStore';
import CG3TopRRGStore from './CorrelationGraph/CG3TopRRGStore';
import CGTopRatingStore from './CorrelationGraph/CGTopRatingStore';
import ListStockStore from './ListStockStore';
import FinancialIndicatorsStore from './CorrelationGraph/DuLieuTaiChinh/FinancialIndicatorsStore';
import TradingSignalStore from './TradingSignalStore';

export const storesContext = React.createContext({
  authStore: new authStore(),
  marketChartStore: new MarketChartStore(MarketIndexStore),
  newActionStore: NewActionStore,
  trendingStore: TrendingStore,
  ptTBChartStore: PTTBChartStore,
  tCTTChartStore: TCTTChartStore,
  khoiNgoaiChartStore: KhoiNgoaiChartStore,
  khoiNgoaiTPChartStore: KhoiNgoaiTPChartStore,
  canhBaoStore: CanhBaoStore,
  topCoPhieuStore: TopCoPhieuStore,
  topListStore: TopListStore,
  rGGChartStore: RGGChartStore,
  rRGWatchListStore: RRGWatchListStore,
  rGGHomeChartStore: RGGHomeChartStore,
  investmentStrategyStore: InvestmentStrategyStore,
  industryVolatilityStore: IndustryVolatilityStore,
  industryDevelopStore: IndustryDevelopStore,
  _ReportingAnalysisStore: ReportingAnalysisStore,
  _TopStockStore: TopStockStore,
  _RecommendedStore: RecommendedStore,
  CorrelationGraphStore: CorrelationGraphStore,
  cG1GeneralStore: CG1GeneralStore,
  cG2TransInfoStore: CG2TransInfoStore,
  cG3TopRRGStore: CG3TopRRGStore,
  cGTopRatingStore: CGTopRatingStore,
  listStockStore: ListStockStore,
  financialIndicatorsStore: FinancialIndicatorsStore,
  tradingSignalStore: TradingSignalStore,
});
